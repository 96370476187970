import React from 'react'
import Links from './Links'

function About() {
    return (
        <>
            <Links />
            <h1>About</h1>
        </>
    )
}

export default About